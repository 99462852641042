import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import CookieConsent,   {getCookieConsentValue} from "react-cookie-consent";
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

import '../assets/scss/main.scss'
import SEO from './seo'

const Layout = ({ children, location }) => {

  let content;

  const trackingLocation = useLocation()

  const handleAcceptCookie = () => {
      initializeAndTrack(trackingLocation)
  };
  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  if (location && location.pathname === '/') {
    content = (
      <div>
        {children}
      </div>
    )
  } else {
    content = (
      <div id="wrapper" className="page">
        <div>
          {children}
        </div>
      </div>
    )
  }

  return (
          <>
            <SEO />
            {content}
            <CookieConsent
              location="bottom"
              onAccept={handleAcceptCookie}
              buttonText="Accetta"
              declineButtonText="Decline"
              cookieName="gatsby-gdpr-google-analytics">
              Questo sito fa uso di cookie per migliorare l'esperienza di navigazione degli utenti e per raccogliere informazioni sull'utilizzo del sito
            </CookieConsent>
          </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
